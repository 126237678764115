<template>
    <div class="content-area">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'ContentArea',
};
</script>

<style lang="scss" scoped>
.content-area {
    background-color: var(--content-area-bg-color);
    border-radius: var(--border-radius);
    padding: 2rem;
    box-shadow: var(--box-shadow);
    margin-bottom: 2rem;
}
</style>
