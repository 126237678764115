<template>
    <page heading="Profile Settings"></page>
</template>

<script>
import Page from '../../layouts/Page.vue';

export default {
    name: 'ProfileSettings',
    components: { Page },
};
</script>
