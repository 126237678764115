<template>
    <div class="page container">
        <header class="page__header">
            <h1
                v-text="heading"
                class="page__heading"
            />
            <div class="page__header-actions">
                <slot name="header-action" />
            </div>
        </header>
        <div
            class="page__content-wrap"
            :class="{ '-has-sidebar': $slots.sidebar }"
        >
            <slot>
                <content-area>
                    <h4 class="h4 page__nothing-here">There's nothing here!</h4>
                </content-area>
            </slot>

            <div class="page__sidebar" v-if="$slots.sidebar">
                <slot name="sidebar" />
            </div>
        </div>
    </div>
</template>

<script>
import ContentArea from '../components/Presentation/ContentArea.vue';

export default {
    name: 'Page',
    components: { ContentArea },
    props: {
        heading: {
            type: String,
            default: '',
        },
    },
};
</script>

<style lang="scss" scoped>
.page {
    margin-bottom: 4rem;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 4rem;
    }

    &__heading {
        margin-bottom: 0;
    }

    &__content {
        padding: 2rem;
        border-radius: var(--border-radius);
    }

    &__content-wrap {
        &.-has-sidebar {
            display: grid;
            grid-template-columns: 1fr 30rem;
            grid-gap: 2rem;
        }
    }

    &__nothing-here {
        margin: 0;
        text-align: center;
    }

    &__sidebar {
        border-radius: var(--border-radius);
        overflow: hidden;
    }
}
</style>
